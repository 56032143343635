import React, {
  useEffect,
  ChangeEvent,
  ReactNode,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import {
  FormControl,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Box } from '../../Box';
import { CancelIcon } from '../../icons';
import { Checkbox } from '../../Checkbox';
import { Badge, ClearButton, Label, OptionBadge, Preloader } from '../common';
import { Typography } from '../../Typography';

import useStyles from './SelectInput.styles';

interface Item<T> {
  label: string;
  value: T;
  badge?: OptionBadge;
  disabled?: boolean;
  tooltipText?: string;
}

interface SelectInputProps<T> {
  items: Item<T>[];
  label?: string;
  value: T | T[] | undefined;
  onChange: (value: T | undefined) => void;
  onLogAction?: (type: string) => void;
  multiple?: boolean;
  testId?: string;
  disabled?: boolean;
  placeholder?: string;
  autocomplete?: boolean;
  required?: boolean;
  clearable?: boolean;
  loading?: boolean;
  tooltip?: ReactNode | null;
  error?: boolean;
  helperText?: string;
  renderOption?: (option: Item<T>) => ReactNode;
  additionalOption?: React.ReactNode;
  emptyOption?: React.ReactNode;
}

export const SelectInput = <T extends string | number>({
  label,
  value,
  items,
  onChange,
  onLogAction,
  multiple = false,
  disabled = false,
  placeholder,
  testId,
  autocomplete = false,
  required = false,
  clearable = false,
  loading = false,
  tooltip,
  error = false,
  helperText,
  renderOption,
  additionalOption,
  emptyOption,
  ...rest
}: SelectInputProps<T>) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [width, setWidth] = useState('auto');

  const selectRef = useRef();
  const inputRef = useRef<HTMLDivElement | null>(null);

  const isThereSomeItemWithBadge = items.some((option) => option.badge);

  React.useEffect(() => {
    const optionLabel = items.find((opt) => opt.value === value)?.label || '';

    if (optionLabel) {
      setInputValue(optionLabel);
    }
  }, [value, items]);

  useEffect(() => {
    if (!value) {
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    setWidth(inputRef.current ? `${inputRef.current.clientWidth}px` : 'auto');
  }, []);

  const handlePopoverOpen = () => {
    onLogAction?.('Открыть выпадающий список');
  };

  return (
    <FormControl
      ref={inputRef}
      className={classNames(classes.selectInput, 'aqa_select_input')}
    >
      {label && <Label label={label} required={required} tooltip={tooltip} />}

      {autocomplete ? (
        <Autocomplete
          data-testid={testId}
          blurOnSelect
          classes={{
            paper: classNames(classes.list, {
              [classes.longList]: !!renderOption,
            }),
            option: classNames(classes.option, {
              [classes.defaultOption]:
                !renderOption && !isThereSomeItemWithBadge,
            }),
          }}
          options={items}
          getOptionLabel={(option) => option.label}
          {...(isThereSomeItemWithBadge
            ? {
                renderOption: (option) => {
                  const isBottom = option.badge?.position === 'bottom';

                  if (!inputValue) {
                    return (
                      <Box
                        display="flex"
                        alignItems={isBottom ? 'start' : 'center'}
                        flexDirection={isBottom ? 'column' : 'row'}
                        gap="8"
                      >
                        {option.badge && (
                          <Box order={isBottom ? 1 : 0}>
                            <Badge
                              title={option.badge.title}
                              description={option.badge.description}
                              color={option.badge.color}
                            />
                          </Box>
                        )}
                        <Box order={isBottom ? 0 : 1}>
                          <Typography component="span" variant="body3">
                            {option.label}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }

                  const words = option.label.split(
                    new RegExp(`(${inputValue})`, 'gi'),
                  );

                  return (
                    <Box
                      display="flex"
                      alignItems={isBottom ? 'start' : 'center'}
                      flexDirection={isBottom ? 'column' : 'row'}
                      gap="8"
                    >
                      {option.badge && (
                        <Box order={isBottom ? 1 : 0}>
                          <Badge
                            title={option.badge.title}
                            description={option.badge.description}
                            color={option.badge.color}
                          />
                        </Box>
                      )}
                      <Box order={isBottom ? 0 : 1}>
                        <Typography
                          component="span"
                          variant="body3"
                          color="textTertiary"
                        >
                          {words.map((part, i) => (
                            <Typography
                              key={i}
                              component="span"
                              variant="body3"
                              color={
                                part.toLowerCase() === inputValue.toLowerCase()
                                  ? 'textPrimary'
                                  : 'textTertiary'
                              }
                            >
                              {part}
                            </Typography>
                          ))}
                        </Typography>
                      </Box>
                    </Box>
                  );
                },
              }
            : {
                renderOption: renderOption
                  ? renderOption
                  : (option) => (
                      <Box>
                        <Typography component="span" variant="body3">
                          {option.label}
                        </Typography>
                      </Box>
                    ),
              })}
          disabled={disabled || loading}
          disableClearable={!clearable}
          closeIcon={<CancelIcon color="disabled" />}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...rest}
              {...params}
              classes={{ root: `${classes.inputRoot} aqa_select` }}
              variant="outlined"
              placeholder={placeholder || label}
              error={error}
              helperText={helperText}
              {...(loading
                ? {
                    InputProps: {
                      endAdornment: <Preloader />,
                    },
                  }
                : {})}
            />
          )}
          inputValue={loading ? 'Загрузка...' : inputValue}
          onInputChange={(event, nextValue) => {
            setInputValue(nextValue);
          }}
          value={items.find((option) => option.value === value)}
          onChange={(event, nextValue) => {
            onChange(nextValue?.value as T);
          }}
          noOptionsText="Ничего не найдено"
        />
      ) : (
        <TextField
          {...rest}
          classes={{
            root: `${classes.inputRoot} ${
              multiple ? 'aqa_multiselect' : 'aqa_select'
            }`,
          }}
          data-testid={testId}
          select={!loading}
          value={loading ? 'Загрузка...' : value}
          onChange={(event: ChangeEvent<{ value: unknown }>) =>
            onChange(event.target.value as T)
          }
          disabled={disabled || loading}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            inputRef: selectRef,
            endAdornment: loading ? (
              <Preloader />
            ) : (
              !disabled &&
              clearable &&
              !!(multiple ? (value as T[])?.length : value) && (
                <ClearButton
                  className={classes.clearButton}
                  onClick={() => onChange(undefined)}
                />
              )
            ),
          }}
          SelectProps={{
            onClose: () => {
              setTimeout(() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (document?.activeElement as any)?.blur();
              }, 0);
            },
            onOpen: handlePopoverOpen,
            multiple,
            displayEmpty: true,
            MenuProps: {
              classes: {
                paper: classNames(classes.list, {
                  [classes.longList]: !!renderOption,
                }),
              },
              anchorOrigin: {
                vertical: 61,
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  width,
                },
              },
            },
            renderValue: (selected) => {
              if (
                (Array.isArray(selected) && !selected.length) ||
                !(selected || typeof selected === 'number')
              ) {
                return (
                  <span className={classes.placeholder}>
                    {placeholder || label}
                  </span>
                );
              }

              if (multiple) {
                if (Array.isArray(selected)) {
                  return selected
                    .map((selectedValue) => {
                      const selectedOption = items.find(
                        (option) => option.value === (selectedValue as T),
                      );
                      return selectedOption ? selectedOption.label : '-';
                    })
                    .join(', ');
                }

                return '';
              } else {
                const selectedOption = items.find(
                  (option) => option.value === (selected as T),
                );

                return selectedOption?.label || '';
              }
            },
          }}
        >
          {items.map((item) => {
            if (item.disabled) {
              return renderOption ? (
                renderOption(item)
              ) : (
                <>
                  {item.badge && (
                    <Box mr="8">
                      <Badge
                        title={item.badge.title}
                        description={item.badge.description}
                        color={item.badge.color}
                      />
                    </Box>
                  )}
                  <Typography variant="body3">{item.label}</Typography>
                </>
              );
            } else {
              return (
                <MenuItem
                  className={classNames(classes.menuItem, {
                    [classes.multiple]: multiple,
                    [classes.defaultOption]: !renderOption && !item.badge,
                  })}
                  value={item.value}
                  key={item.value}
                  // disabled={item.disabled}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  {multiple && (
                    <Checkbox
                      checked={
                        Array.isArray(value) && value.includes(item.value)
                      }
                    />
                  )}
                  {renderOption ? (
                    renderOption(item)
                  ) : (
                    <>
                      {item.badge && (
                        <Box mr="8">
                          <Badge
                            title={item.badge.title}
                            description={item.badge.description}
                            color={item.badge.color}
                          />
                        </Box>
                      )}
                      <Typography variant="body3">{item.label}</Typography>
                    </>
                  )}
                </MenuItem>
              );
            }
          })}
          {additionalOption && !!items.length && (
            <MenuItem
              disabled
              className={classNames(classes.menuItem, classes.additionalOption)}
              key="emptyOption"
            >
              {additionalOption}
            </MenuItem>
          )}
          {emptyOption && !items.length && (
            <MenuItem
              disabled
              className={classNames(classes.menuItem, classes.additionalOption)}
              key="emptyOption"
            >
              {emptyOption}
            </MenuItem>
          )}
        </TextField>
      )}
    </FormControl>
  );
};
