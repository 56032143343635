/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  createAction,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';
import { ActivityLogService } from '@vk-hr-tek/core/activity-log';
import { Calendar } from '@vk-hr-tek/core/calendar';

import {
  EventListItem as EventListEntity,
  CancelReasonItem as CancelReason,
} from '@app/gen/events';

import { ThunkExtra } from '../../../app/store';
import { EventsDetailService } from '../../services';
import { GetEventDto, CancelEventDto, ReturnEventDto } from '../../dto';
import { EventsState, EventsWithRootState } from '../events.state';
import { EventWithValidators } from '../../types';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const setCancelReasons = createAction<CancelReason[] | null>(
  'events/setCancelReasons',
);

export const setStartEventId = createAction<string | null>(
  'events/setStartEventId',
);

const setStatus = createAction<{
  requestId: string;
  status: 'loading' | 'loading-text';
}>('events/setStatus');

const setActionTypeForLoadingText = createAction<{
  actionType: string;
}>('events/setActionTypeForLoadingText');

export const eventView = createAsyncThunk<void, GetEventDto, ThunkExtra>(
  'user/eventView',
  async (getEventDto, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(ActivityLogService).eventView(getEventDto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEvent = createAsyncThunk<
  EventWithValidators,
  GetEventDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getEvent',
  async (
    getEventDto,
    { rejectWithValue, getState, dispatch, extra: { inject }, requestId },
  ) => {
    try {
      const state = getState().events;
      const eventsService = inject(EventsDetailService);
      const calendar = inject(Calendar);

      calendar.reset();

      let cancelReasons = state.cancelReasons;

      cancelReasons = await eventsService.getCancelReasons(getEventDto);
      dispatch(setCancelReasons(cancelReasons));

      let result: EventWithValidators = await eventsService.get(getEventDto);
      let timeout = 500;
      let requestsCounter = 1;

      dispatch(eventView(getEventDto));

      while (
        Array.isArray(result.active_nodes) &&
        result.active_nodes.length > 0 &&
        result.active_nodes.every(
          (node) =>
            node.action.type.startsWith('system') &&
            node.action.type !== 'system_booking_approve' &&
            node.action.type !== 'system_booking_limits_exceeded_approve' &&
            node.action.type !== 'system_booking_trip_ordering' &&
            node.action.type !== 'system_booking_trip_limit',
        )
      ) {
        if (getState().events.detail.currentId !== requestId) {
          return result;
        }

        if (
          result.active_nodes.every(
            (node) =>
              node.action.type === 'system_validate_sign' ||
              node.action.type === 'system_booking_hook' ||
              node.action.type === 'system_booking_trip_create' ||
              node.action.type === 'system_booking_trip_limit_approved',
          )
        ) {
          dispatch(
            setStatus({
              requestId,
              status: 'loading-text',
            }),
          );
          dispatch(
            setActionTypeForLoadingText({
              actionType: result.active_nodes[0]?.action.type,
            }),
          );
        } else {
          dispatch(
            setStatus({
              requestId,
              status: 'loading',
            }),
          );
        }

        await delay(timeout);
        timeout = requestsCounter % 2 ? timeout : timeout * 2;
        requestsCounter++;
        result = await eventsService.get(getEventDto);
      }

      if (result.nodes && result.nodes.length) {
        const validators = (
          await Promise.all(
            result.nodes.map(async (node) => {
              if (
                node.actions.some((action) => action.form_attributes.length)
              ) {
                return {
                  id: node.node_id,
                  validators: (
                    await eventsService.getNodeValidators({
                      nodeId: node.node_id,
                      eventId: getEventDto.id,
                    })
                  ).validators,
                };
              }

              return null;
            }),
          )
        ).filter((validator) => validator?.validators?.length);

        if (validators.length) {
          result.validators = {};

          validators.forEach((validator) => {
            if (result.validators && validator) {
              result.validators[validator.id] = validator.validators.map(
                (option) => {
                  if (
                    option.type === 'working_day' &&
                    option.from_attribute_id === option.to_attribute_id
                  ) {
                    return {
                      attribute_id: option.from_attribute_id,
                      type: 'single_working_day',
                    };
                  }

                  if (
                    option.type === 'holydays_exclusion' &&
                    option.from_attribute_id === option.to_attribute_id
                  ) {
                    return {
                      attribute_id: option.from_attribute_id,
                      type: 'single_holydays_exclusion',
                    };
                  }

                  return option;
                },
              );
            }
          });
        }
      }

      const attributesDates = result.attributes
        .filter((item) => item.type === 'date')
        .map((attributeDate) =>
          new Date(
            typeof attributeDate?.value === 'string'
              ? attributeDate?.value
              : '',
          ).getFullYear(),
        )
        .sort((startDate, endDate) => startDate - endDate);

      if (attributesDates && attributesDates.length) {
        const minYear = attributesDates[0];
        const maxYear = attributesDates[attributesDates.length - 1];
        const yearsRange = Array.from(
          { length: maxYear - minYear + 1 },
          (_, k) => minYear + k,
        );
        await Promise.all([
          ...yearsRange.map((year) =>
            calendar.download(new Date(String(year)), result.employee?.id),
          ),
        ]);
      }
      calendar.download(new Date(), result.employee?.id);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const cancelEvent = createAsyncThunk<
  void,
  {
    values: CancelEventDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'cancelEvent',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(EventsDetailService).cancelEvent(
        plainToClass(CancelEventDto, values),
      );

      actions.resolve(null);
      dispatch(getEvent({ id: values.eventId }));
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const returnEvent = createAsyncThunk<
  void,
  {
    values: ReturnEventDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'returnEvent',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(EventsDetailService).returnEvent(
        plainToClass(ReturnEventDto, values),
      );

      actions.resolve(null);
    } catch (err) {
      actions.reject(null);
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setResendedNode = createAction<{
  eventId: string;
  nodeId: string;
  timestamp: number;
}>('events/setResendedNode');

export const eventDetailReducers = (
  builder: ActionReducerMapBuilder<EntityState<EventListEntity> & EventsState>,
) => {
  builder.addCase(setCancelReasons, (state, action) => {
    state.cancelReasons = action.payload;
  });
  builder.addCase(setStartEventId, (state, action) => {
    state.detail.startEventId = action.payload;
  });
  builder.addCase(setStatus, (state, action) => {
    if (state.detail.currentId === action.payload.requestId) {
      state.detail.status = action.payload.status;
    }
  });
  builder.addCase(setActionTypeForLoadingText, (state, action) => {
    state.detail.actionTypeForLoadingText = action.payload.actionType;
  });
  builder.addCase(getEvent.pending, (state, { meta }) => {
    state.detail.currentId = meta.requestId;
    state.detail.status = 'loading';
    state.detail.data = null;
    state.detail.error = null;
    state.blink = !!meta?.arg?.blink;
  });
  builder.addCase(getEvent.fulfilled, (state, { payload, meta }) => {
    if (meta.requestId === state.detail.currentId) {
      state.detail.status = 'complete';
      state.detail.data = payload;
      state.detail.error = null;
      state.detail.currentId = null;
    }
  });
  builder.addCase(getEvent.rejected, (state, { payload, error, meta }) => {
    if (meta.requestId === state.detail.currentId) {
      state.detail.status = 'failed';
      state.detail.error =
        payload ||
        ({
          info: (error && error.message) || 'Unknown error',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
      state.detail.currentId = null;
      state.blink = false;
    }
  });
  builder.addCase(
    setResendedNode,
    (state, { payload: { eventId, nodeId, timestamp } }) => {
      const exists = state.resendedNodes.find(
        (node) => node.eventId === eventId && node.nodeId === nodeId,
      );

      if (exists) {
        exists.timestamp = timestamp;
      } else {
        state.resendedNodes.push({ eventId, nodeId, timestamp });
      }
    },
  );
};
