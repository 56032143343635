import React, { useCallback, useState } from 'react';

import { Form } from 'react-final-form';
import {
  Add as AddIcon,
  ClearSharp as ClearSharpIcon,
  ListAlt as ListAltIcon,
} from '@material-ui/icons';

import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Content } from '@vk-hr-tek/ui/Content';
import { Dialog } from '@vk-hr-tek/ui/Dialog';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { Link } from '@vk-hr-tek/ui/Link';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';

import { useDispatch, useSelector } from '@app/hooks';

import { RemoveSubstituteDto } from '../../../dto';
import { removeSubstitute, selectSubstitutes } from '../../../slice';
import { UserRouter } from '../../../types';

import { EmployeePositionAndPersonalNumber } from './EmployeePositionAndPersonalNumber';

export const Substitutes = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const router = useInject<UserRouter>(UserRouter);

  const substitutes = useSelector(selectSubstitutes);
  const filteredSubstitutes = substitutes.filter(
    ({ eventTypeGroup }) => isDesktop || eventTypeGroup.length,
  );

  const [removeDialogIsOpened, setRemoveDialogIsOpened] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [employeeCompanyName, setEmployeeCompanyName] = useState('');
  const [substituteId, setSubstituteId] = useState('');
  const [substituteName, setSubstituteName] = useState('');
  const [eventTypeId, setEventTypeId] = useState('');

  const handleClose = () => {
    setEmployeeId('');
    setEmployeeCompanyName('');
    setSubstituteId('');
    setSubstituteName('');
    setEventTypeId('');
    setRemoveDialogIsOpened(false);
  };

  const handleOpen =
    (
      selectEmployeeId: string,
      selectEmployeeCompanyName: string,
      selectedUserId: string,
      selectedUserName: string,
      selectedEventTypeId: string,
    ) =>
    () => {
      setEmployeeId(selectEmployeeId);
      setEmployeeCompanyName(selectEmployeeCompanyName);
      setSubstituteId(selectedUserId);
      setSubstituteName(selectedUserName);
      setEventTypeId(selectedEventTypeId);
      setRemoveDialogIsOpened(true);
    };

  const onSubmit = (values: RemoveSubstituteDto) =>
    new Promise((resolve) => {
      const closeDialog = (value: unknown) => {
        resolve(value);
        handleClose();
      };

      dispatch(
        removeSubstitute({
          values: {
            employeeId,
            substituteId,
            eventTypeId,
          },
          actions: {
            resolve: () => {
              closeDialog(values);
            },
            reject: closeDialog,
          },
        }),
      );
    });

  const handleSubstitutes = useCallback(
    (employeeIdParam: string, companyIdParam: string) => () => {
      router.goToProfileSubstitutes({
        employeeId: employeeIdParam,
        companyId: companyIdParam,
      });
    },
    [router],
  );

  if (!substitutes.length) {
    return null;
  }

  return (
    <Box pb="20">
      <Content>
        <Accordion
          defaultExpanded
          padding={isDesktop ? 'medium' : 'small'}
          title={
            <Typography variant={isDesktop ? 'h5' : 'subtitle1'}>
              Заместители
            </Typography>
          }
        >
          {!isDesktop && !filteredSubstitutes.length ? (
            <Typography
              variant={isDesktop ? 'body2' : 'body3'}
              color="textTertiary"
            >
              У вас пока нет заместителей
            </Typography>
          ) : (
            <Box>
              {filteredSubstitutes.map(
                ({ company, employee, eventTypeGroup }, index) => (
                  <>
                    <Box
                      key={`${company.name}${employee.position}`}
                      className="aqa_profile_substitutes"
                    >
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Box
                            mb={isDesktop ? '0' : '16'}
                            mr={isDesktop ? '32' : '0'}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            height="calc(100% - 24px)"
                          >
                            <Typography
                              variant={isDesktop ? 'h6' : 'subtitle2'}
                            >
                              {company.name}
                            </Typography>
                            {employee.showPosition &&
                              employee.position &&
                              (isDesktop ? (
                                <Tooltip
                                  title={
                                    <>
                                      <Box mb="8">
                                        <Typography
                                          variant="subtitle2"
                                          color="contrast"
                                        >
                                          {company.name}
                                        </Typography>
                                      </Box>
                                      <Box mb="8">
                                        <Typography
                                          variant="caption"
                                          color="contrast"
                                        >
                                          {employee.position}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        variant="caption"
                                        color="textTertiary"
                                      >
                                        Табельный номер:{' '}
                                        {employee.personalNumber}
                                      </Typography>
                                    </>
                                  }
                                  placement="bottom"
                                >
                                  <span>
                                    <EmployeePositionAndPersonalNumber
                                      position={employee.position}
                                      personalNumber={employee.personalNumber}
                                    />
                                  </span>
                                </Tooltip>
                              ) : (
                                <EmployeePositionAndPersonalNumber
                                  position={employee.position}
                                  personalNumber={employee.personalNumber}
                                />
                              ))}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box mb={isDesktop ? '24' : '0'}>
                            {isDesktop && (
                              <Button
                                icon={
                                  <AddIcon color="primary" fontSize="medium" />
                                }
                                onClick={handleSubstitutes(
                                  employee.id,
                                  company.id,
                                )}
                                size="large"
                              />
                            )}
                          </Box>
                        </Grid>
                        {eventTypeGroup
                          .filter(
                            ({ substitutes: eventTypeSubstitutes }) =>
                              eventTypeSubstitutes.length,
                          )
                          .map(
                            ({
                              eventType,
                              substitutes: eventTypeSubstitutes,
                            }) => (
                              <>
                                <Grid item xs={12} md={3}>
                                  <Box
                                    alignItems="center"
                                    display="flex"
                                    height={isDesktop ? '56' : 'auto'}
                                    mb={isDesktop ? '0' : '16'}
                                    mr={isDesktop ? '32' : '0'}
                                  >
                                    <EllipsisText rows={2}>
                                      <Typography
                                        variant={isDesktop ? 'body2' : 'body3'}
                                        color={
                                          isDesktop
                                            ? 'textSecondary'
                                            : 'textTertiary'
                                        }
                                      >
                                        {eventType.name}
                                      </Typography>
                                    </EllipsisText>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                  <Box mb={isDesktop ? '24' : '16'}>
                                    <Grid container spacing="8">
                                      {eventTypeSubstitutes.map(
                                        (
                                          { id, name, personalNumber },
                                          eventTypeIndex,
                                        ) => (
                                          <Grid
                                            item
                                            xs={isDesktop ? 4 : 12}
                                            key={id}
                                          >
                                            <Box
                                              display="flex"
                                              justifyContent="space-between"
                                              alignItems="start"
                                              height="100%"
                                              width="100%"
                                              px="12"
                                              py="8"
                                              bgcolor="bg.greyscale.primary"
                                              radius="l"
                                              mb={
                                                !isDesktop &&
                                                eventTypeIndex !==
                                                  eventTypeSubstitutes.length -
                                                    1
                                                  ? '8'
                                                  : '0'
                                              }
                                            >
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="start"
                                                width="100%"
                                              >
                                                <Box>
                                                  <Box fontSize={14} mb="4">
                                                    <EllipsisText rows={1}>
                                                      <Typography variant="body3">
                                                        {name}
                                                      </Typography>
                                                    </EllipsisText>
                                                  </Box>
                                                  <Box
                                                    display="flex"
                                                    alignItems="center"
                                                  >
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                      color="text.light.tertirary"
                                                      mr="8"
                                                      fontSize={16}
                                                    >
                                                      <ListAltIcon fontSize="inherit" />
                                                    </Box>
                                                    <Typography
                                                      variant="caption"
                                                      color="textTertiary"
                                                    >
                                                      {personalNumber}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                {isDesktop && (
                                                  <Link
                                                    size="large"
                                                    onClick={handleOpen(
                                                      employee.id,
                                                      company.name,
                                                      id,
                                                      name,
                                                      eventType.id,
                                                    )}
                                                    startIcon={
                                                      <ClearSharpIcon color="disabled" />
                                                    }
                                                    stroke={false}
                                                  />
                                                )}
                                              </Box>
                                            </Box>
                                          </Grid>
                                        ),
                                      )}
                                    </Grid>
                                  </Box>
                                </Grid>
                              </>
                            ),
                          )}
                      </Grid>
                    </Box>
                    {isDesktop && index !== filteredSubstitutes.length - 1 && (
                      <Box mx="-32" mb="32" mt="8">
                        <Divider />
                      </Box>
                    )}
                  </>
                ),
              )}
              <Dialog onClose={handleClose} open={removeDialogIsOpened}>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        p="48"
                      >
                        <Box pb="24">
                          <Typography variant="h5">
                            Удаление заместителя
                          </Typography>
                        </Box>
                        <Box>
                          <Box pb="16">
                            <Typography color="textTertiary">
                              Вы уверены, что хотите удалить заместителя у
                              работника?
                            </Typography>
                          </Box>
                          <Box p="16" radius="l" bgcolor="bg.greyscale.primary">
                            <Grid container spacing="8">
                              <Grid item xs={4}>
                                <Typography color="textTertiary">
                                  Юрлицо:
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant="body2">
                                  {employeeCompanyName}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography color="textTertiary">
                                  Заместитель:
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant="body2">
                                  {substituteName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        p="24"
                        display="flex"
                        justifyContent="end"
                        bgcolor="bg.greyscale.primary"
                      >
                        <Box mr="12">
                          <Button
                            disabled={submitting}
                            onClick={handleClose}
                            size="large"
                            variant="tertiaryLight"
                          >
                            Назад
                          </Button>
                        </Box>
                        <Box ml="12">
                          <Button
                            loading={submitting}
                            size="large"
                            type="submit"
                          >
                            Удалить
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                />
              </Dialog>
            </Box>
          )}
        </Accordion>
      </Content>
    </Box>
  );
};
