import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';
import { FormatService } from '@vk-hr-tek/core/format';

import { EventListItem as EventListEntity } from '@app/gen/events';

import {
  EventsCreateMapper,
  EventsDetailMapper,
  EventsListMapper,
  EventsMapper,
} from '../mappers';

import { EventsWithRootState as RootState } from './events.state';

export const selectEvents = createSelector(
  [
    (state: RootState) => state.events.currentIds,
    (state: RootState) => state.events.entities,
    (_: RootState, inject: Inject) => inject(EventsListMapper),
  ],
  (currentIds, entities, format) => {
    return currentIds
      .map((id) => entities[id])
      .filter((x): x is EventListEntity => x !== undefined)
      .map((event) => format.processListEntity({ ...event }));
  },
);

export const selectAbsencesEventsList = createSelector(
  [
    (state: RootState) => state.events.currentIds,
    (state: RootState) => state.events.entities,
    (_: RootState, inject: Inject) => inject(EventsListMapper),
  ],
  (currentIds, entities, format) => {
    return currentIds
      .map((id) => entities[id])
      .filter((x): x is EventListEntity => x !== undefined)
      .map((event) => format.processAbsencesListEntity({ ...event }));
  },
);

export const selectTotal = (state: RootState) => state.events.total;

export const selectTotalDocuments = (state: RootState) =>
  state.events.totalDocuments;

export const selectTotalMy = (state: RootState, inject?: Inject) => {
  const format = (inject as unknown as Inject)(FormatService);

  return state.events.totalMy ? format.convertNumber(state.events.totalMy) : '';
};

export const selectTotalMyTeam = createSelector(
  [
    (state: RootState) => state.events.totalMyTeam,
    (_: RootState, inject: Inject) => inject(FormatService),
  ],
  (totalMyTeam, format) => totalMyTeam && format.convertNumber(totalMyTeam),
);

export const selectFinished = (state: RootState) => state.events.finished;

export const selectCompanyFilters = createSelector(
  [
    (state: RootState) => state.events.filters,
    (_: RootState, inject: Inject) => inject(EventsMapper),
  ],
  (filters, format) => filters && format.processCompanyListFilters(filters),
);

export const selectEmployeeFilters = createSelector(
  [
    (state: RootState) => state.events.filters,
    (_: RootState, inject: Inject) => inject(EventsMapper),
  ],
  (filters, format) => filters && format.processListFilters(filters),
);

export const selectCancelReasons = (state: RootState) =>
  state.events.cancelReasons;

export const selectPepSignIsSubmittingStatus = (state: RootState) => {
  return state.events.pepSign.status === 'loading';
};

export const selectPepSignBatchIsSubmittingStatus = (state: RootState) => {
  return state.events.pepSignBatch.status === 'loading';
};

export const selectUkepSignIsSubmittingStatus = (state: RootState) => {
  return state.events.ukepSign.status === 'loading';
};

export const selectUkepSignBatchIsSubmittingStatus = (state: RootState) => {
  return state.events.ukepSignBatch.status === 'loading';
};

export const selectUnepSignIsSubmittingStatus = (state: RootState) => {
  return state.events.unepSign.status === 'loading';
};

export const selectUnepSignBatchIsSubmittingStatus = (state: RootState) => {
  return state.events.unepSignBatch.status === 'loading';
};

export const selectEventValidatorsByNodeId = createSelector(
  [
    (state: RootState) => state.events.detail,
    (_: RootState, inject: Inject, id: string) => id,
  ],
  (detail, id) => {
    return detail && detail.data && detail.data.validators
      ? detail.data.validators[id]
      : [];
  },
);

export const selectError = (state: RootState) => state.events.error;

export const selectDetailError = (state: RootState) =>
  state.events.detail.error;

export const selectStatus = (state: RootState) => state.events.status;

export const selectDetailStatus = (state: RootState) =>
  state.events.detail.status;

export const selectDetailActionTypeForLoadingText = (state: RootState) =>
  state.events.detail.actionTypeForLoadingText;

export const selectDetailStartId = (state: RootState) =>
  state.events.detail.startEventId;

export const selectEventRaw = (state: RootState) => state.events.detail.data;

export const selectEvent = createSelector(
  [
    (state: RootState) => state.events.detail.data,
    (_: RootState, inject: Inject) => inject(EventsDetailMapper),
    (state: RootState) => state.events.competencyOptions?.items,
  ],
  (detail, format, competencyOptions) => {
    return detail
      ? format.processDetailEntity(detail, competencyOptions)
      : null;
  },
);

export const selectCanCreateEvent = (state: RootState) =>
  state.events.canCreateEvent;

export const selectCreationStatus = (state: RootState) =>
  state.events.creation.status;

export const selectCreateCompanyOptionsError = (state: RootState) =>
  state.events.creationCompany.error;

export const selectSelectedCompanyEventType = (state: RootState) =>
  state.events.creationCompany.selected;

export const selectCreationItems = createSelector(
  [
    (state: RootState) => state.events.creation.items,
    (_: RootState, inject: Inject) => inject(EventsCreateMapper),
  ],
  (items, format) => (items ? format.processCreateEventItem(items) : null),
);

export const selectCreationCompanyStatus = (state: RootState) =>
  state.events.creationCompany.status;

export const selectCreationCompanyItems = createSelector(
  [
    (state: RootState) => state.events.creationCompany.items,
    (_: RootState, inject: Inject) => inject(EventsCreateMapper),
  ],
  (items, format) =>
    items ? format.processCreateEventItemCompany(items) : null,
);

export const selectCanCreateEventType = (state: RootState) =>
  state.events.canCreateEventType;

export const selectEventTypeOptions = (state: RootState) =>
  state.events.eventTypes.companies;

export const selectEventTypeOptionsStatus = (state: RootState) =>
  state.events.eventTypes.status;

export const selectEventTypeOptionsError = (state: RootState) =>
  state.events.eventTypes.error;

export const selectEventFlowStatus = (state: RootState) =>
  state.events.flow.status;

export const selectEventFlowTree = (state: RootState) => state.events.flow.tree;

export const selectEventFlowError = (state: RootState) =>
  state.events.flow.error;

export const selectEventFlowPrintStatus = (state: RootState) =>
  state.events.printFlow.status;

export const selectResendedNode = createSelector(
  [
    (state: RootState) => state.events.resendedNodes,
    (
      _: RootState,
      inject: Inject,
      { eventId }: { eventId: string; nodeId: string },
    ) => eventId,
    (
      _: RootState,
      inject: Inject,
      { nodeId }: { eventId: string; nodeId: string },
    ) => nodeId,
  ],
  (resendedNodes, eventId, nodeId) =>
    resendedNodes.find(
      (node) => node.eventId === eventId && node.nodeId === nodeId,
    ),
);

export const selectCodeRequestingError = (state: RootState) =>
  state.events.codeRequesting.error;

export const selectBlink = (state: RootState) => state.events.blink;

export const selectCreateEventTypeOptionsStatus = (state: RootState) =>
  state.events.createEventTypeOptions.status;

export const selectAssignableRoles = createSelector(
  [
    (state: RootState) => state.events.createEventTypeOptions.options,
    (_: RootState, inject: Inject) => inject(EventsCreateMapper),
    (_: RootState, inject: Inject, { id }: { id: string; required: boolean }) =>
      id,
    (
      _: RootState,
      inject: Inject,
      { required }: { id: string; required: boolean },
    ) => required,
  ],
  (options, mapper, id, required) =>
    mapper.processAssignableRoles(
      required
        ? options[id]?.assignableRoles?.required
        : options[id]?.assignableRoles?.optional,
      id,
      required,
    ),
);

export const selectCopyDocuments = createSelector(
  [
    (state: RootState) => state.events.createEventTypeOptions.options,
    (_: RootState, inject: Inject, id: string) => id,
  ],
  (options, id) => options[id]?.copyDocuments || [],
);

export const selectCopyAttributes = createSelector(
  [
    (state: RootState) => state.events.createEventTypeOptions.options,
    (_: RootState, inject: Inject, id: string) => id,
  ],
  (options, id) => options[id]?.copyAttributes || [],
);
