import { injectable } from 'inversify';
import { at } from 'lodash';

import { Calendar } from '@vk-hr-tek/core/calendar';

import { Validator } from '../responses';

@injectable()
export class EventsValidationService {
  constructor(private calendar: Calendar) {}

  hasAvailableDatesInRange({
    fromDate,
    toDate,
    toAtributeId: id,
    validators,
  }: {
    fromDate: Date;
    toDate: Date;
    toAtributeId: string;
    validators: Validator[];
  }) {
    return validators
      .filter(({ type }) => type !== 'no_overlap' && type !== 'same_month')
      .some((dateValidator) => {
        if (dateValidator.type === 'min_date') {
          return this.calendar.isLessThan(
            toDate,
            this.calendar.startOfDay(new Date(dateValidator.min_date)),
          );
        }

        if (dateValidator.type === 'max_date') {
          return this.calendar.isMoreThan(
            toDate,
            this.calendar.endOfDay(new Date(dateValidator.max_date)),
          );
        }

        if (
          dateValidator.type === 'divisible_by' &&
          dateValidator.to_attribute_id === id
        ) {
          return this.calendar.isMoreThan(
            this.calendar.endOfDay(
              this.calendar.addWithoutHolidayDays(
                fromDate,
                dateValidator.divisible_by - 1,
              ),
            ),
            toDate,
          );
        }

        if (
          dateValidator.type === 'min_duration' &&
          dateValidator.to_attribute_id === id
        ) {
          if (dateValidator.days_count < 0) {
            return this.calendar.isWithinDays(toDate, {
              days_count: dateValidator.days_count
                ? dateValidator.days_count - 1
                : 0,
              from_date: fromDate,
              working_days_only: dateValidator.working_days_only,
            });
          }
          return this.calendar.isNotWithinDays(toDate, {
            days_count: dateValidator.days_count
              ? dateValidator.days_count - 1
              : 0,
            from_date: fromDate,
            working_days_only: dateValidator.working_days_only,
          });
        }

        if (
          dateValidator.type === 'max_duration' &&
          dateValidator.to_attribute_id === id
        ) {
          if (dateValidator.days_count < 0) {
            return this.calendar.isNotWithinDays(toDate, {
              days_count: dateValidator.days_count
                ? dateValidator.days_count - 1
                : 0,
              from_date: fromDate,
              working_days_only: dateValidator.working_days_only,
            });
          }

          return this.calendar.isWithinDays(toDate, {
            days_count: dateValidator.days_count
              ? dateValidator.days_count - 1
              : 0,
            from_date: fromDate,
            working_days_only: dateValidator.working_days_only,
          });
        }

        if (
          dateValidator.type === 'working_day' &&
          dateValidator.to_attribute_id === id
        ) {
          return (
            this.calendar.isHolidayOrWeekend(fromDate) &&
            this.calendar.areAllHolidaysInInterval(toDate, {
              from_date: fromDate,
            })
          );
        }

        if (
          dateValidator.type === 'holydays_exclusion' &&
          dateValidator.to_attribute_id === id
        ) {
          return (
            this.calendar.isHoliday(fromDate) &&
            this.calendar.areAllHolidaysInInterval(toDate, {
              from_date: fromDate,
              exclude_weekends: true,
            })
          );
        }

        if (
          dateValidator.type === 'weekend_exclusion' &&
          dateValidator.to_attribute_id === id
        ) {
          return (
            this.calendar.isSameDay(fromDate, toDate) &&
            ((this.calendar.isHolidayOrWeekend(this.calendar.add(toDate, 1)) &&
              dateValidator.existing_vacations.find((vacation) => {
                return this.calendar.isSameDay(
                  new Date(vacation),
                  this.calendar.addBusinessDays(toDate, 1),
                );
              })) ||
              (this.calendar.isHolidayOrWeekend(this.calendar.sub(toDate, 1)) &&
                dateValidator.existing_vacations.find((vacation) =>
                  this.calendar.isSameDay(
                    new Date(vacation),
                    this.calendar.subBusinessDays(toDate, 1),
                  ),
                )))
          );
        }
      });
  }

  isDateInvalid({
    currentDate,
    validators,
    toAtributeId: id,
    values: formValues,
    prefix,
  }: {
    currentDate: Date;
    validators: Validator[];
    toAtributeId: string;
    values: Record<string, string>;
    prefix: string;
  }) {
    const day = this.calendar.startOfDay(currentDate);

    return validators.some((dateValidator) => {
      if (dateValidator.type === 'min_date') {
        return this.calendar.isLessThan(
          day,
          this.calendar.startOfDay(new Date(dateValidator.min_date)),
        );
      }

      if (dateValidator.type === 'max_date') {
        return this.calendar.isMoreThan(
          day,
          this.calendar.endOfDay(new Date(dateValidator.max_date)),
        );
      }

      if (
        dateValidator.type === 'min_duration' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        return this.calendar.isNotWithinDays(day, {
          days_count: dateValidator.days_count
            ? dateValidator.days_count - 1
            : 0,
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          working_days_only: dateValidator.working_days_only,
        });
      }

      if (
        dateValidator.type === 'max_duration' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        return this.calendar.isWithinDays(day, {
          days_count: dateValidator.days_count ? dateValidator.days_count : 0,
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          working_days_only: dateValidator.working_days_only,
        });
      }

      if (
        dateValidator.type === 'vacation_available' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        return this.calendar.isWithinDays(day, {
          days_count: dateValidator.vacation_available_days
            ? dateValidator.vacation_available_days
            : 0,
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          working_days_only: false,
        });
      }

      if (
        dateValidator.type === 'same_month' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        return !this.calendar.isSameMonth(
          day,
          new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
        );
      }

      if (
        dateValidator.type === 'divisible_by' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        return (
          this.calendar.differenceInDays(
            day,
            this.calendar.startOfDay(
              this.calendar.sub(
                new Date(
                  at(
                    formValues,
                    `${prefix}_attribute_${dateValidator.from_attribute_id}`,
                  )[0],
                ),
                1,
              ),
            ),
            { excludeHolidays: true },
          ) %
            dateValidator.divisible_by !==
          0
        );
      }

      if (
        dateValidator.type === 'working_day' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        const fromDate = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        return (
          this.calendar.isHolidayOrWeekend(fromDate) &&
          this.calendar.areAllHolidaysInInterval(day, {
            from_date: fromDate,
          })
        );
      }

      if (
        dateValidator.type === 'holydays_exclusion' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        const fromDate = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        return (
          this.calendar.isHoliday(fromDate) &&
          this.calendar.areAllHolidaysInInterval(day, {
            from_date: fromDate,
            exclude_weekends: true,
          })
        );
      }

      if (
        dateValidator.type === 'single_working_day' &&
        dateValidator.attribute_id === id
      ) {
        return this.calendar.isHolidayOrWeekend(day);
      }

      if (
        dateValidator.type === 'single_holydays_exclusion' &&
        dateValidator.attribute_id === id
      ) {
        return this.calendar.isHoliday(day);
      }

      if (
        dateValidator.type === 'weekend_exclusion' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        const fromDate = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        return (
          this.calendar.isSameDay(fromDate, day) &&
          ((this.calendar.isHolidayOrWeekend(this.calendar.add(day, 1)) &&
            dateValidator.existing_vacations.find((vacation) =>
              this.calendar.isSameDay(
                new Date(vacation),
                this.calendar.addBusinessDays(day, 1),
              ),
            )) ||
            (this.calendar.isHolidayOrWeekend(this.calendar.sub(day, 1)) &&
              dateValidator.existing_vacations.find((vacation) =>
                this.calendar.isSameDay(
                  new Date(vacation),
                  this.calendar.subBusinessDays(day, 1),
                ),
              )))
        );
      }

      if (
        dateValidator.type === 'no_overlap' &&
        dateValidator.from_attribute_id === id
      ) {
        return this.calendar.isInNotWithinIntervals(day, {
          existing_dates: dateValidator.existing_dates,
        });
      }

      if (
        dateValidator.type === 'no_overlap' &&
        dateValidator.to_attribute_id === id &&
        at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0]
      ) {
        return this.calendar.isInNotWithinIntervalsAfterDate(day, {
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          existing_dates: dateValidator.existing_dates,
        });
      }

      return false;
    });
  }

  /** Определить учитывается ли указанное количество выходных дней
   * при планировании указанного количества рабочих дней
   * @template T
   * @param {string} [message] Сообщение об ошибке
   * @param [options]
   * @param {string} options.rangeStart Дата начала отпуска
   * @param {number} options.workingDays Количество рабочих дней
   * @param {number} options.minWeekendDays Минимальное количество выходных дней
   * @returns {(value: any) => string | undefined}
   * */
  minWeekendDaysValidator = (
    message: string,
    options?: {
      rangeStart: string;
      workingDays: number;
      minWeekendDays: number;
    },
  ) => {
    return (value: any, allValues?: any) => {
      if (!options || !value) {
        return;
      }
      const startDate = at(allValues, options.rangeStart)[0];
      const duration =
        this.calendar.differenceInCalendarDays(
          new Date(value),
          new Date(startDate),
        ) + 1;
      const countOfWeekendDaysInInterval =
        startDate &&
        this.calendar.countOfWeekendsInInterval(
          new Date(startDate),
          new Date(value),
        );

      if (
        duration < options.workingDays ||
        options.minWeekendDays <= countOfWeekendDaysInInterval
      ) {
        return;
      }

      return message;
    };
  };
}
