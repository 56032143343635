import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class VacationScheduleMyDeadlineResponse {
  @IsUUID()
  @IsOptional()
  schedule_id?: string;

  @IsDateString()
  @IsOptional()
  deadline?: string;
}

export class VacationFavoritesResponse {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  event_ids: string[];

  @IsInt()
  @IsNotEmpty()
  total: number;

  @IsInt()
  @IsNotEmpty()
  max_allowed: number;
}

export class VacationExcludedEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsNotEmpty()
  first_name: string;

  @IsString()
  @IsOptional()
  middle_name?: string;

  @IsString()
  @IsNotEmpty()
  last_name: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;
}

export class VacationScheduleStart_options {
  @IsDateString()
  @IsOptional()
  include_employee_hired_before?: string;

  @IsDateString()
  @IsOptional()
  exclude_disabled_contract_until?: string;

  @IsDateString()
  @IsOptional()
  exclude_unpayed_vacation_until?: string;

  @IsDateString()
  @IsOptional()
  exclude_maternity_leave_until?: string;

  @ValidateNested({ each: true })
  @Type(() => VacationExcludedEmployee)
  @IsArray()
  @IsNotEmpty()
  exclude_employees: VacationExcludedEmployee[];
}

export class VacationGroupApprover {
  @IsString()
  @IsNotEmpty()
  group_name: string;
}

export class VacationManagerApprover {
  @IsIn(['legal', 'operational', 'functional', 'direct'])
  @IsString()
  @IsNotEmpty()
  type: 'legal' | 'operational' | 'functional' | 'direct';

  @IsInt()
  @IsOptional()
  approve_levels?: number;
}

export class VacationEntityApprover {
  @IsDateString()
  @IsNotEmpty()
  deadline: string;

  @IsBoolean()
  @IsNotEmpty()
  can_edit: boolean;

  @ValidateNested()
  @Type(() => VacationManagerApprover)
  @IsOptional()
  manager_approve?: VacationManagerApprover;

  @ValidateNested()
  @Type(() => VacationGroupApprover)
  @IsOptional()
  group_approve?: VacationGroupApprover;
}

export class DurationValidator {
  @IsInt()
  @IsNotEmpty()
  days: number;

  @IsIn(['calendar', 'work'])
  @IsString()
  @IsNotEmpty()
  unit: 'calendar' | 'work';
}

export class VacationValidators {
  @IsBoolean()
  @IsNotEmpty()
  must_schedule_all_days: boolean;

  @IsBoolean()
  @IsNotEmpty()
  must_schedule_14days: boolean;

  @IsInt()
  @IsOptional()
  days_must_be_divisible_by?: number;

  @ValidateNested()
  @Type(() => DurationValidator)
  @IsOptional()
  min_duration?: DurationValidator;

  @ValidateNested()
  @Type(() => DurationValidator)
  @IsOptional()
  max_duration?: DurationValidator;

  @IsBoolean()
  @IsNotEmpty()
  limit_to_calculated_days: boolean;

  @IsBoolean()
  @IsNotEmpty()
  min_5_days_between_not_workdays: boolean;

  @IsBoolean()
  @IsOptional()
  weekend_exclusion?: boolean;

  @IsBoolean()
  @IsOptional()
  holydays_exclusion?: boolean;

  @IsBoolean()
  @IsOptional()
  min_specific_weekend_days?: boolean;
}

export class VacationScheduleEvent_stats {
  @IsInt()
  @IsNotEmpty()
  total: number;

  @IsInt()
  @IsNotEmpty()
  new: number;

  @IsInt()
  @IsNotEmpty()
  on_approve: number;

  @IsInt()
  @IsNotEmpty()
  send_to_1c: number;

  @IsInt()
  @IsNotEmpty()
  canceled: number;

  @IsInt()
  @IsNotEmpty()
  completed: number;
}

export class VacationSchedule {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['new', 'starting', 'active', 'completed'])
  @IsString()
  @IsNotEmpty()
  status: 'new' | 'starting' | 'active' | 'completed';

  @ValidateNested()
  @Type(() => VacationScheduleEvent_stats)
  @IsNotEmpty()
  event_stats: VacationScheduleEvent_stats;

  @IsInt()
  @IsNotEmpty()
  year: number;

  @IsDateString()
  @IsNotEmpty()
  active_to: string;

  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsBoolean()
  @IsNotEmpty()
  send_to_1c: boolean;

  @IsString()
  @IsOptional()
  company_comment?: string;

  @IsBoolean()
  @IsNotEmpty()
  allow_employee_comment: boolean;

  @IsBoolean()
  @IsNotEmpty()
  add_previous_vacation: boolean;

  @ValidateNested()
  @Type(() => VacationValidators)
  @IsNotEmpty()
  validators: VacationValidators;

  @IsDateString()
  @IsNotEmpty()
  employee_deadline: string;

  @ValidateNested({ each: true })
  @Type(() => VacationEntityApprover)
  @IsArray()
  @IsNotEmpty()
  approvers: VacationEntityApprover[];

  @IsIn(['unit', 'all'])
  @IsString()
  @IsOptional()
  teammate_access?: 'unit' | 'all';

  @ValidateNested()
  @Type(() => VacationScheduleStart_options)
  @IsNotEmpty()
  start_options: VacationScheduleStart_options;

  @IsDateString()
  @IsNotEmpty()
  created_at: string;
}

export class CreateVacationScheduleResponse {
  @IsInt()
  @IsNotEmpty()
  success: number;

  @IsInt()
  @IsNotEmpty()
  errors: number;

  @ValidateNested({ each: true })
  @Type(() => VacationSchedule)
  @IsArray()
  @IsNotEmpty()
  items: VacationSchedule[];
}

export class CompanyVacationSchedule {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsInt()
  @IsNotEmpty()
  year: number;

  @IsIn(['new', 'starting', 'active', 'completed'])
  @IsString()
  @IsNotEmpty()
  status: 'new' | 'starting' | 'active' | 'completed';

  @IsBoolean()
  @IsNotEmpty()
  favorites_allowed: boolean;
}

export class CompanyVacationSchedulesListItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;

  @IsBoolean()
  @IsOptional()
  has_actions?: boolean;

  @ValidateNested({ each: true })
  @Type(() => CompanyVacationSchedule)
  @IsArray()
  @IsNotEmpty()
  vacation_schedules: CompanyVacationSchedule[];
}

export class VacationScheduleListResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyVacationSchedulesListItem)
  @IsArray()
  @IsNotEmpty()
  companies: CompanyVacationSchedulesListItem[];
}

export class AdminVacationSchedulesListItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;

  @IsBoolean()
  @IsOptional()
  has_actions?: boolean;

  @ValidateNested({ each: true })
  @Type(() => VacationSchedule)
  @IsArray()
  @IsNotEmpty()
  vacation_schedules: VacationSchedule[];
}

export class VacationScheduleAdminListResponse {
  @ValidateNested({ each: true })
  @Type(() => AdminVacationSchedulesListItem)
  @IsArray()
  @IsNotEmpty()
  companies: AdminVacationSchedulesListItem[];
}

export class VacationAdminCompany {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  has_schedule_for_next_year: boolean;
}

export class VacationAdminCompaniesListResponse {
  @ValidateNested({ each: true })
  @Type(() => VacationAdminCompany)
  @IsArray()
  @IsNotEmpty()
  companies: VacationAdminCompany[];
}
