import React, { ReactNode } from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';
import { Link } from '../Link';

interface DialogContentProps {
  onClose: () => void;
  title?: string;
  titleChip?: ReactNode;
  fullScreen?: boolean;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const DialogContent = ({
  children,
  fullScreen,
  title,
  titleChip,
  actions,
  onClose,
}: DialogContentProps) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Box
        py={fullScreen ? '32' : '48'}
        px={fullScreen ? '16' : '48'}
        flexGrow="1"
      >
        {title && (
          <Box
            display="flex"
            alignItems="flex-start"
            mb="24"
            flexDirection="column"
          >
            {fullScreen && (
              <Link size="small" onClick={onClose} stroke={false}>
                Назад
              </Link>
            )}
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant="h5">{title}</Typography>
              {titleChip}
            </Box>
          </Box>
        )}
        {children}
      </Box>
      {actions &&
        (fullScreen ? (
          <Box display="flex" justifyContent="center" gap="16" px="16" pb="32">
            {actions}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap="16"
            p="16"
            bgcolor="bg.greyscale.primary"
          >
            {actions}
          </Box>
        ))}
    </Box>
  );
};
