import {
  createEntityAdapter,
  EntityState,
  SerializedError,
} from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';
import { VacationScheduleMyDeadlineResponse } from '@vk-hr-tek/app/app/gen/vacations';

import { RootState } from '@app/store';
import { AbsenceCompanyItem, AbsenceListGroup } from '@app/gen/absences';
import {
  CreateEventCompanyItem,
  EventListItem,
  CreateEventOption,
  CreateEventItem,
} from '@app/gen/events';

export interface AbsencesState {
  companies: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: CreateEventCompanyItem[];
    error: AppError | null;
  };
  absenceCompanies: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: AbsenceCompanyItem[];
    error: AppError | null;
  };
  absenceList: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    additionalStatus:
      | 'idle'
      | 'loading'
      | 'loadingAfterFail'
      | 'complete'
      | 'failed';
    items: AbsenceListGroup[];
    error: AppError | null;
    currentIds: string[];
    total: number;
    totalDocuments: number | null;
    totalMy?: number;
    totalMyTeam?: number;
    finished: number;
    filters: Filter[] | null;
  };
  absencesEventsList: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: AbsenceListGroup[];
    error: AppError | null;
    currentIds: string[];
    total: number;
    totalDocuments: number | null;
    totalMy?: number;
    totalMyTeam?: number;
    finished: number;
    filters: {
      data: Filter[] | null;
      id: string | null;
    };
  };
  deadline: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    info: VacationScheduleMyDeadlineResponse;
    error: AppError | null;
  };
  eventCreationError: AppError | null;
  creation: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    options: CreateEventOption[] | [];
    items: CreateEventItem[] | [];
    error: AppError | null;
  };
  canCreateEvent: boolean;
}

export interface AbsencesWithRootState extends RootState {
  absences: AbsencesState & EntityState<EventListItem | AbsenceListGroup>;
}

export const initialState: AbsencesState = {
  companies: {
    status: 'idle',
    items: [],
    error: null,
  },
  absenceCompanies: {
    status: 'idle',
    items: [],
    error: null,
  },
  absenceList: {
    additionalStatus: 'idle',
    status: 'idle',
    items: [],
    error: null,
    currentIds: [],
    total: 0,
    totalDocuments: null,
    finished: 0,
    filters: null,
  },
  absencesEventsList: {
    status: 'idle',
    items: [],
    error: null,
    currentIds: [],
    total: 0,
    totalDocuments: null,
    finished: 0,
    filters: {
      data: null,
      id: null,
    },
  },
  deadline: {
    status: 'idle',
    info: {},
    error: null,
  },
  eventCreationError: null,
  creation: {
    status: 'idle',
    options: [],
    items: [],
    error: null,
  },
  canCreateEvent: false,
};

export const absencesAdapter = createEntityAdapter<
  EventListItem | AbsenceListGroup
>();

export const startLoading = (state: AbsencesState) => {
  state.absenceList.status = 'loading';
  state.absenceList.error = null;
  state.absenceList.total = 0;
  state.absenceList.finished = 0;
  state.absenceList.currentIds = [];
};

export const completeLoading = (state: AbsencesState) => {
  state.absenceList.status = 'complete';
  state.absenceList.error = null;
};

export const setError = (
  state: AbsencesState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.absenceList.status = 'failed';
  state.absenceList.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const startEventsLoading = (state: AbsencesState) => {
  state.absencesEventsList.status = 'loading';
  state.absencesEventsList.error = null;
  state.absencesEventsList.total = 0;
  state.absencesEventsList.finished = 0;
  state.absencesEventsList.currentIds = [];
};

export const completeEventsLoading = (state: AbsencesState) => {
  state.absencesEventsList.status = 'complete';
  state.absencesEventsList.error = null;
};

export const setEventsError = (
  state: AbsencesState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.absencesEventsList.status = 'failed';
  state.absencesEventsList.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const setEventCreationError = (
  state: AbsencesState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.eventCreationError =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};
