/* eslint-disable @typescript-eslint/no-explicit-any */
import { FORM_ERROR } from 'final-form';
import {
  createAsyncThunk,
  createAction,
  ActionReducerMapBuilder,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';
import { Crypto } from '@vk-hr-tek/core/crypto';

import { EventListItem as Event } from '@app/gen/events';

import { ThunkExtra } from '../../../app/store';
import { EventsListService } from '../../services';
import { GetEventsDto } from '../../dto';
import {
  EventsState,
  EventsWithRootState,
  startLoading,
  completeLoading,
  setError,
  eventsAdapter,
} from '../events.state';

export const setFilters = createAction<Filter[] | null>('events/setFilters');

export const getEvents = createAsyncThunk<
  {
    data: Event[];
    total: number;
    totalMy?: number;
    totalMyTeam?: number;
    finished: number;
  },
  GetEventsDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getEvents',
  async (
    getEventsDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().events;
      const eventsService = inject(EventsListService);

      let filters = state.filters;

      if (!filters) {
        filters = await eventsService.getFilters();
        dispatch(setFilters(filters));
      }

      return await eventsService.get(getEventsDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getDocumentCount = createAsyncThunk<
  {
    totalCount: number;
  },
  GetEventsDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getDocumentCount',
  async (
    getEventsDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().events;
      const eventsService = inject(EventsListService);

      let filters = state.filters;

      if (!filters) {
        filters = await eventsService.getFilters();
        dispatch(setFilters(filters));
      }

      return await eventsService.getDocumentCount(getEventsDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAdditionalEvents = createAsyncThunk<
  {
    data: Event[];
    total: number;
  },
  GetEventsDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getAdditionalEvents',
  async (
    getEventsDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().events;
      const eventsService = inject(EventsListService);

      let filters = state.filters;

      if (!filters) {
        filters = await eventsService.getFilters();
        dispatch(setFilters(filters));
      }

      return await eventsService.get(getEventsDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUkepBatch = createAsyncThunk<
  void,
  {
    values: {
      data: {
        event_id: string;
        document_id: string;
      }[];
      tsp?: string;
      certificateId: string;
    };
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: { [FORM_ERROR]: AppError }) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'events/signUkepBatch',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = await inject(EventsListService);
      const crypto = await inject(Crypto);

      const [{ hashes }, certificate] = await Promise.all([
        service
          .getHashes({
            hashes: values.data.map((value) => ({
              event_id: value.event_id,
            })),
          })
          .catch(() => {
            throw new Error('Ошибка получения хэша документа');
          }),
        crypto.getSertificateById(values.certificateId),
      ]);

      const dataWithSignatures = await Promise.all(
        hashes.map(async (hash) => {
          return {
            ...hash,
            signature: await crypto
              .signHash(certificate, hash.hash, values?.tsp)
              .catch((err) => {
                throw new AppError('client', {
                  code: 500,
                  message: err?.message ?? 'Ошибка при подписании',
                  data: {
                    error_code: values?.tsp ? 'sign_hash_failed' : '',
                  },
                });
              }),
          };
        }),
      );

      interface CompanyBatchSignRequest {
        [key: string]: {
          event_id: string;
          documents: {
            signature: string;
            document_hash: string;
          }[];
        };
      }

      const transformedData = dataWithSignatures.reduce(
        (acc: CompanyBatchSignRequest, item) => {
          acc[item.event_id] = {
            event_id: item.event_id,
            documents: [
              ...((acc[item.event_id] && acc[item.event_id].documents) || []),
              {
                signature: item.signature,
                document_hash: item.hash,
              },
            ],
          };
          return acc;
        },
        {},
      );

      try {
        await service.ukepBatchSign({
          events: Object.values(transformedData),
        });
      } catch (error) {
        throw new Error('Ошибка отправки подписи');
      }

      actions.resolve(null);
    } catch (err) {
      if (err instanceof AppError) {
        actions.reject({ [FORM_ERROR]: err });

        return rejectWithValue(classToPlain(err) as AppError);
      }

      const appError = new AppError('client', {
        code: 500,
        message: 'Упс! Что-то пошло не так.',
      });

      if (err instanceof Error && err.message) {
        appError.title = err.message;
      }

      actions.reject({ [FORM_ERROR]: appError });

      return rejectWithValue(classToPlain(appError) as AppError);
    }
  },
);

export const eventsListReducers = (
  builder: ActionReducerMapBuilder<EntityState<Event> & EventsState>,
) => {
  builder.addCase(setFilters, (state, action) => {
    state.filters = action.payload;
  });
  builder.addCase(getEvents.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(getEvents.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.total = payload.total;
    state.totalMy = payload.totalMy;
    state.totalMyTeam = payload.totalMyTeam;
    state.finished = payload.finished;
    state.currentIds = payload.data.map(({ id }) => id);
    eventsAdapter.upsertMany(state, payload.data);
  });

  builder.addCase(getAdditionalEvents.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.total = payload.total;
    state.currentIds = [
      ...state.currentIds,
      ...payload.data.map(({ id }) => id),
    ];
    eventsAdapter.upsertMany(state, payload.data);
  });
  builder.addCase(getEvents.rejected, (state, { payload, error, meta }) => {
    if (!meta.aborted) {
      setError(state, { payload, error });
    }
  });
  builder.addCase(getDocumentCount.pending, (state) => {
    state.totalDocuments = null;
  });
  builder.addCase(getDocumentCount.fulfilled, (state, { payload }) => {
    state.totalDocuments = payload.totalCount;
  });
};
