/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { CheckboxInput as Input } from '../../input';

export const CheckboxInput = ({
  label,
  labelPlacement,
  labelColor,
  labelVariant,
  name,
  tooltip,
  disabled,
  onChange,
  ...rest
}: FieldProps<boolean | undefined, FieldRenderProps<boolean | undefined>>) => (
  <Field name={name} {...rest}>
    {({ input }) => (
      <Input
        {...input}
        label={label}
        labelPlacement={labelPlacement}
        labelColor={labelColor}
        labelVariant={labelVariant}
        checked={input.value}
        tooltip={tooltip}
        disabled={disabled}
        onChange={input.onChange || onChange}
      />
    )}
  </Field>
);
