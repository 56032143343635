import React, { useState, useCallback } from 'react';

import LogoutIcon from '@material-ui/icons/ExitToApp';
import { Menu, MenuItem } from '@material-ui/core';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Box } from '@vk-hr-tek/ui/Box';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { IconButton } from '@vk-hr-tek/ui/IconButton';
import { loadChat, selectStatus } from '@vk-hr-tek/core/chat';
import { Dialog } from '@vk-hr-tek/ui/Dialog';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';

import { useDispatch, useSelector } from '@app/hooks';

import { selectFullName } from '../../../user';
import { UserCard } from '../common/UserCard';
import { UserRouter } from '../../../user/types';

import { LogoutConfirmation } from './LogoutConfirmation';
import { useStyles } from './User.styles';

const stringAvatar = (name: string) =>
  `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

interface UserProps {
  userRepresentative: boolean;
}

export const User = ({ userRepresentative }: UserProps) => {
  const router = useInject<UserRouter>(UserRouter);
  const classes = useStyles();
  const dispatch = useDispatch();

  const userFullName = useSelector(selectFullName);
  const chatStatus = useSelector(selectStatus);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const handleProfileMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuProfileClick = useCallback(() => {
    router.goToProfile();
    handleMenuClose();
  }, [router]);

  const handleMenuQuitClick = useCallback(() => {
    handleMenuClose();
    setIsConfirmationDialogOpen(true);
  }, []);

  const handleChatSupportClick = () => {
    if (chatStatus === 'complete') {
      window.webim.api.chat.start();
    } else {
      dispatch(loadChat());
    }
    handleMenuClose();
  };

  return (
    <Box ml="8">
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleProfileMenuOpen}
        size="small"
      >
        <Avatar children={stringAvatar(userFullName || '')} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 55,
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <Box py="8">
          {(!window.REACT_APP_VKHRTEK_LK_DISABLED || userRepresentative) && (
            <Box mb="12">
              <UserCard
                onClick={handleMenuProfileClick}
                userFullName={userFullName || ''}
              />
            </Box>
          )}
          {window.webim && window.webim.api && (
            <MenuItem onClick={handleChatSupportClick}>Чат поддержки</MenuItem>
          )}
          <Box py="8" px="16">
            <Divider />
          </Box>
          <MenuItem onClick={handleMenuQuitClick} className={classes.link}>
            <Box display="inline" mr="8" lineHeight={1}>
              <LogoutIcon />
            </Box>
            Выйти
          </MenuItem>
        </Box>
      </Menu>
      <Dialog
        open={chatStatus === 'loading'}
        classes={{ paper: classes.dialog }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" p="16">
          <CircularProgress size={40} />
        </Box>
      </Dialog>
      <LogoutConfirmation
        onClose={() => setIsConfirmationDialogOpen(false)}
        open={isConfirmationDialogOpen}
      />
    </Box>
  );
};
