import React, { useCallback } from 'react';

import { Form } from 'react-final-form';

import { Box } from '@vk-hr-tek/ui/Box';
import { useInject } from '@vk-hr-tek/core/ioc';
import {
  FormButton,
  FormError,
  FormKnownValue,
  FormKnownValues,
} from '@vk-hr-tek/ui/form';
import { FilePreview } from '@vk-hr-tek/ui/FilePreview';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useDispatch, useSelector } from '@app/hooks';

import { useLogout } from '../../../auth';
import {
  selectFullName,
  selectPhoneNumber,
  initCertificateCreation,
  initCertificateCreationEsia,
  selectCertificateReleaseMethod,
} from '../../slice';
import { Page } from '../../ui/page';
import { UserApiService } from '../../services';

export const CertificateNotExist = () => {
  const dispatch = useDispatch();
  const logout = useLogout();
  const service = useInject(UserApiService);

  const userFullName = useSelector(selectFullName);
  const phone = useSelector(selectPhoneNumber);
  const releaseMethod = useSelector(selectCertificateReleaseMethod);
  const authInstance = useSelector(selectAuthInstance);

  const loadUnep = useCallback(async () => {
    const res = await service.getUnep();
    return res.file;
  }, [service]);

  const onSubmit = () =>
    new Promise((resolve) => {
      if (releaseMethod === 'esia') {
        dispatch(
          initCertificateCreationEsia({
            actions: { resolve },
          }),
        );
      } else {
        dispatch(
          initCertificateCreation({
            actions: { resolve },
          }),
        );
      }
    });

  return (
    <Page title="Выпуск электронной подписи">
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          hasValidationErrors,
          dirtySinceLastSubmit,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mb="24">
              <Typography variant="body3" color="textSecondary">
                Чтобы подписывать документы в системе электронного
                документооборота, вам нужно получить усиленную
                неквалифицированную электронную цифровую подпись (УНЭП)
              </Typography>
            </Box>

            <Box mb="16">
              <FormKnownValues>
                <FormKnownValue
                  label="Работник"
                  value={userFullName}
                  caption={phone}
                />
              </FormKnownValues>
            </Box>

            <Box mb="8">
              <FilePreview
                onLoad={loadUnep}
                view="fileLarge"
                isClickable
                showSnackbarOnDesktop
                snackbarMessage="Заявление на выдачу УНЭП"
                snackbarAction="Ok"
                linkText="Заявление на выдачу УНЭП"
              />
            </Box>

            <Box mt="40">
              <FormButton disabled={submitting || hasValidationErrors}>
                Выпустить
              </FormButton>
            </Box>
            {!dirtySinceLastSubmit && submitError && (
              <FormError error={submitError} />
            )}
          </form>
        )}
      />
      {authInstance.auth_type === 'pass' && (
        <Box mt="20">
          <Link onClick={logout} size="small" variant="tertiary">
            Выход к QR-коду
          </Link>
        </Box>
      )}
    </Page>
  );
};
